const Url = "https://client.securefx.net";
// const Url = "https://portal.berrymarkets.com";


// const IsApprove = {
//   is_app: 1,
//   auth: "c79669-b28d13-a62a8a"  ,
//   user_id: "2",
//   is_crm: 1,
// };
const IsApprove = "";
export { Url, IsApprove };
